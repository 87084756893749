import React, { useState } from "react";
import { imageUrls } from "./Utils/imageUrls";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Captions from "yet-another-react-lightbox/plugins/captions";
import "yet-another-react-lightbox/plugins/captions.css";
import "yet-another-react-lightbox/plugins/counter.css";
import Counter from "yet-another-react-lightbox/plugins/counter";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Video from "yet-another-react-lightbox/plugins/video";
import YouTube from "react-youtube";

const Gallery = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const imagesPerPage = 6;

  // Logic to determine current page's images
  const indexOfLastImage = currentPage * imagesPerPage;
  const indexOfFirstImage = indexOfLastImage - imagesPerPage;
  const currentImages = imageUrls.slice(indexOfFirstImage, indexOfLastImage);
  const imageSlides = imageUrls.filter((image) => image.type !== "video");
  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Open lightbox based on URL
  const openLightbox = (url) => {
    const index = imageUrls.findIndex((image) => image.src === url);
    if (index !== -1) {
      setLightboxOpen(true);
      setSelectedImageIndex(index);
    }
  };

  const opts = {
    width: "100%",
    height: "100%",
  };

  // Close lightbox
  const closeLightbox = () => {
    setLightboxOpen(false);
    setSelectedImageIndex(null);
  };

  return (
    <section className=" bg-green-50" data-section id="galleryRef">
      <div className="max-w-screen-xl mx-auto ">
        <div className="text-center block relative z-20 mx-auto py-4">
          <h1 className="text-5xl font-poppins font-medium my-8 !text-gray-800">
            Gallery
          </h1>
        </div>
        <div className="grid grid-cols-2 md:grid-cols-3 gap-4 px-2">
          {/* Map through the current images */}
          {currentImages.map((image, index) => (
            <div
              key={index}
              onClick={() => {
                if (image.type !== "video") openLightbox(image.src);
              }}
            >
              {image.type === "video" ? (
                <div className="cursor-pointer h-44 sm:h-64 w-full rounded-lg overflow-hidden">
                  <YouTube
                    videoId={`${image.sources[0].src}`}
                    className="w-full h-full object-cover"
                    opts={opts}
            
                  />
                </div>
              ) : (
                <img
                  className="cursor-pointer h-44 sm:h-64 w-full rounded-lg object-cover"
                  src={image.src}
                  alt={`galleryImage ${indexOfFirstImage + index + 1}`}
                />
              )}
            </div>
          ))}
        </div>
        <Lightbox
          open={lightboxOpen}
          plugins={[Captions, Counter, Thumbnails, Video]}
          close={closeLightbox}
          index={selectedImageIndex}
          slides={imageSlides.map((image) => ({
            src: image.src,
            // title: image.title,
            // description: image.description,
          }))}
        />

        <div className="py-8">
          <div className="join">
            {/* prev button */}
            <button
              className="join-item btn border-none bg-slate-500 hover:bg-emerald-700"
              onClick={() => paginate(currentPage - 1)}
              disabled={currentPage === 1} // Disable the button if on the first page
            >
              «
            </button>

            {/* Pagination buttons */}
            {Array.from({
              length: Math.ceil(imageUrls.length / imagesPerPage),
            }).map((_, index) => (
              <button
                key={index}
                className={`join-item btn !border-none text-slate-200  hover:bg-emerald-700 ${
                  currentPage === index + 1 ? "bg-emerald-500" : "bg-slate-700"
                }`}
                onClick={() => paginate(index + 1)}
              >
                {index + 1}
              </button>
            ))}

            {/* next button */}
            <button
              className={`join-item btn border-none bg-slate-500 hover:bg-emerald-700 disabled:cursor-not-allowed ${
                currentPage === Math.ceil(imageUrls.length / imagesPerPage)
                  ? "cursor-not-allowed"
                  : "cursor-pointer"
              }`}
              onClick={() => paginate(currentPage + 1)}
              disabled={
                currentPage === Math.ceil(imageUrls.length / imagesPerPage)
              }
            >
              »
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Gallery;
