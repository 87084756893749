export const imageUrls = [
  {
    type: "video",
    width: 1280,
    height: 720,
    sources: [
      {
        src: "SLQcko56i9g",
        type: "video/mp4",
      },
      {
        src: "https://youtu.be/SLQcko56i9g?si=CcvcrQBbXnDor8ey",
        type: "video/mp4",
      },
    ],
  },
  {
    src: "https://ik.imagekit.io/uev27r0wn7/Picture-1.webp?updatedAt=1710313215284",
    title: "Slide Title Two",
    description: "Slide Description Two",
  },
  {
    src: "https://ik.imagekit.io/uev27r0wn7/Picture-2.webp?updatedAt=1710313216760",
    title: "Slide Title Three",
    description: "Slide Description Three",
  },
  {
    src: "https://ik.imagekit.io/uev27r0wn7/Picture-3.webp?updatedAt=1710313216849",
    title: "Slide Title Four",
    description: "Slide Description Four",
  },
  {
    src: "https://ik.imagekit.io/uev27r0wn7/picture-4.webp?updatedAt=1710313216236",
    title: "Slide Title Five",
    description: "Slide Description Five",
  },
  {
    src: "https://ik.imagekit.io/uev27r0wn7/Picture-5.webp?updatedAt=1710313216988",
    title: "Slide Title Six",
    description: "Slide Description Six",
  },
  // {
  //   src: "https://images.unsplash.com/photo-1700751639138-e6628c42b558?q=80&w=1740&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  //   title: "Slide Title Seven",
  //   description: "Slide Description Seven",
  // },
  // {
  //   src: "https://plus.unsplash.com/premium_photo-1698442704192-086265cf630e?q=80&w=1738&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  //   title: "Slide Title Eight",
  //   description: "Slide Description Eight",
  // },
  // {
  //   src: "https://images.unsplash.com/photo-1700753227268-8832c285591e?q=80&w=1744&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  //   title: "Slide Title Nine",
  //   description: "Slide Description Nine",
  // },
  // {
  //   src: "https://images.unsplash.com/photo-1700769025506-6c3dcb9ec9b7?q=80&w=1664&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  //   title: "Slide Title Ten",
  //   description: "Slide Description Ten",
  // },
];
