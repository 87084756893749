import React from "react";
import heroImage from "../../assets/hero.webp";

const Hero = () => {
  return (
    <div data-section id="homeRef">
      <div className="relative bg-gray-200 dark:bg-gray-200 bg-center bg-cover bg-no-repeat bg-fixed bg-[url('https://ik.imagekit.io/c1jhxlxiy/bg.png?updatedAt=1710050052323')]">
        <div className="absolute top-0 left-0 w-full h-full !bg-gray-50 opacity-90" />
        <div className="container hero-content mx-auto px-6 pt-16 md:px-12 lg:pt-[1.2rem] lg:px-7">
          <div className="flex items-center flex-wrap px-2 md:px-0 text-left">
            <div className="relative lg:w-6/12 lg:py-24 xl:py-32">
              <p className="mb-4 lg:w-10/12 uppercase tracking-wider text-sm font-medium !text-gray-800">
                Welcome to Asa Foundation
              </p>
              <h1 className="font-medium text-3xl md:text-4xl lg:w-[95%] !text-gray-800">
                Honoring Asa Kumari's Legacy,{""}
                <span className="bg-gradient-to-tl from-emerald-700 via-emerald-500 to-emerald-800 bg-clip-text text-transparent">
                  Empowering Children
                </span>
              </h1>
              <p className="mt-6 lg:w-[95%] font-light !text-gray-600">
                The Asa Foundation stands as a tribute to the extraordinary life
                and legacy of Asa Kumari, a luminary in both the realms of
                Chartered Accountancy and Indian Classical dance. Inspired by
                her unwavering dedication to excellence and her commitment to
                giving back to the community, the foundation is dedicated to
                empowering underprivileged children through education.
              </p>
              <a
                href="mailto:info@asafoundation.in"
                className="btn bg-emerald-600 text-gray-100 mt-6 border-none hover:bg-emerald-400"
              >
                Contact Us
              </a>
            </div>
            <div className="ml-auto  lg:w-6/12 mt-6 md:my-4">
              <img
                src={heroImage}
                className="relative rounded-box"
                alt="hero illustration"
                loading="lazy"
                sizes="(max-width: 540px) 100vw, 540px"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
